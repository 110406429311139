import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SchoolIcon from '@mui/icons-material/School';
import {Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import {LEARN_ACTION, UPDATE_LEARN_ACTION} from 'app/actions/dataAction';
import Cookies from 'js-cookie';

export default function LearnPanel(props) {
  const dispatch = useDispatch();

  const learnState = useSelector((state) => state.LEARN_REDUCER) || {}; // Default to an empty object if state.learn is undefined
  // console.log('LEARN STATE', learnState);

  const [expandedIndex, setExpandedIndex] = useState(0);

  const iconstyle = {fontSize: '14px'};

  const learn = useSelector((state) => state.LEARN_REDUCER);
  const lead = useSelector((state) => state.LEARN_REDUCER?.lead);
  const leadsearch = useSelector((state) => state.LEARN_REDUCER?.leadsearch);

  const [isLargeScreen, setIsLargeScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth >= 1343 : false
  );

  // Update isLargeScreen whenever the window size changes
  if (typeof window !== 'undefined') {
    useEffect(() => {
      const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1343);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [window.innerWidth]);
  }

  // Use isLargeScreen to determine the value of isOpen

  const initialOptions = useMemo(
    () => [
      {
        title: 'Use Leadsearch',
        explainer: 'Leadsearch helps you to find sales from 200m+ companies globally',
        complete: leadsearch || false, // Default to false if undefined
        icon: <ScreenSearchDesktopOutlinedIcon style={iconstyle} />,
        buttontext: 'Find Leads',
        buttonlink: '/leadsearch',
        cookie: 'leadsearch',
      },
      {
        title: 'Visit a Lead',
        explainer: 'Each company is called a Lead. Visit a Lead to see more information',
        complete: lead,
        icon: <PersonIcon style={iconstyle} />,
        buttontext: 'See Lead',
        buttonlink: '/uk/Find-Me-Sales-Ltd/13664185',
        cookie: 'lead',
      },
      {
        title: 'View Companies',
        explainer: 'The Companies page is where you find all the companies you add or enrich',
        complete: learn?.leads || false, // Default to false if undefined
        icon: <PeopleIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'See Leads' : 'Start Free',
        buttonlink: props.profile?.status ? '/companies' : '/pricing?d=Monthly',
        cookie: 'leads',
      },
      {
        title: 'Add a Company',
        explainer: 'Add a Company from Leadsearch, the add button or by uploading a csv',
        complete: props.profile?.leads?.length > 0,
        icon: <AddIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'Add Company' : 'Start Free',
        buttonlink: props.profile?.status ? '/companies?a=add' : '/pricing?d=Monthly',
        cookie: 'addlead',
      },
      {
        title: 'Enrich a Company',
        explainer:
          'Get our AI to perform a web search and read the website for company and people info',
        complete: props.profile?.enriched?.length > 0,
        icon: <AutoFixHighIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'Enrich Company' : 'Start Free',
        buttonlink: props.profile?.status ? '/leadsearch' : '/pricing?d=Monthly',
        cookie: 'enrichlead',
      },
      {
        title: 'Write a Template',
        explainer: 'Templates can be used in Automation Flows to pre-load an email',
        complete: learnState.template || false, // Default to false if undefined
        icon: <DynamicFeedIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'Write Template' : 'Start Free',
        buttonlink: props.profile?.status ? '/templates' : '/pricing?d=Monthly',
        cookie: 'template',
      },
      {
        title: 'Create Automation',
        explainer: 'Automation Flows generate Actions that you can take with a single click',
        complete: learnState.automation || false, // Default to false if undefined
        icon: <BrightnessAutoOutlinedIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'Create Automation' : 'Start Free',
        buttonlink: props.profile?.status ? '/automation' : '/pricing?d=Monthly',
        cookie: 'automation',
      },
      {
        title: 'Connect Email',
        explainer: 'Connect your mailbox to send emails directly from the platform',
        complete: Array.isArray(props.profile?.mailbox?.user) && props.profile.mailbox.user.length > 0,
        icon: <MailOutlineIcon style={iconstyle} />,
        buttontext: props.profile?.status ? 'Connect Email' : 'Start Free',
        buttonlink: props.profile?.status ? '/emails' : '/pricing?d=Monthly',
        cookie: 'email',
      },
    ],
    [
      learn?.leadsearch,
      learn?.automation,
      learn?.lead,
      learn?.isOpen,
      learn?.template,
      props.profile,
      // learnState,
      // leadsearch,
    ]
  );

  const [options, setOptions] = useState(initialOptions);

  // useEffect(() => {
  //   const payload = {
  //     _id: props?.profile?._id,
  //     learnPanel: props?.profile?.learnPanel,
  //     isOpen:
  //       isLargeScreen &&
  //       (props.profile?.learnPanel?.isOpen ||
  //         Cookies.get('isOpen') === 'true' ||
  //         Cookies.get('isOpen') === undefined),
  //     lead: props.profile?.learnPanel?.lead || Cookies.get('lead') === 'true',
  //     leadsearch: props.profile?.learnPanel?.leadsearch || Cookies.get('leadsearch') === 'true',
  //     leads: props.profile?.learnPanel?.leads || Cookies.get('leads') === 'true',
  //     addlead:
  //       props.profile?.leads?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.addlead || Cookies.get('addlead') === 'true',
  //     enrichlead:
  //       props.profile?.enriched?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.enrichlead || Cookies.get('enrichlead') === 'true',
  //     template: props.profile?.learnPanel?.template || Cookies.get('template') === 'true',
  //     automation: props.profile?.learnPanel?.automation || Cookies.get('automation') === 'true',
  //     email:
  //       props.profile?.mailbox?.user?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.email || Cookies.get('email') === 'true',
  //   };
  //   // console.log(
  //   //   'DISPATCH LEARN ACTION',
  //   //   payload,
  //   //   props.profile,
  //   //   isLargeScreen,
  //   //   Cookies.get('isOpen')
  //   // );
  //   if (isLargeScreen) {
  //     payload.isOpen = true;
  //   }
  //   dispatch(LEARN_ACTION(payload));
  // }, [dispatch, props.profile]);
  useEffect(() => {
    const isOpenFromProfile = props?.profile?.learnPanel?.isOpen;
    const isOpenFromCookie = Cookies.get('isOpen');
  
    // Determine if the panel should be open
    let isOpenValue = false;
  
    if (isLargeScreen) {
      if (isOpenFromProfile !== undefined) {
        isOpenValue = isOpenFromProfile; // Use profile state if available
      } else if (isOpenFromCookie === undefined) {
        isOpenValue = true; // Open only the first time if no cookie exists
        Cookies.set('isOpen', 'true'); // Store this to prevent future auto-opening
      }
    }
  
    const payload = {
      _id: props?.profile?._id,
      learnPanel: props?.profile?.learnPanel,
      isOpen: isOpenValue,
      lead: props.profile?.learnPanel?.lead || Cookies.get('lead') === 'true',
      leadsearch: props.profile?.learnPanel?.leadsearch || Cookies.get('leadsearch') === 'true',
      leads: props.profile?.learnPanel?.leads || Cookies.get('leads') === 'true',
      addlead:
        props.profile?.leads?.length > 0
          ? true
          : props.profile?.learnPanel?.addlead || Cookies.get('addlead') === 'true',
      enrichlead:
        props.profile?.enriched?.length > 0
          ? true
          : props.profile?.learnPanel?.enrichlead || Cookies.get('enrichlead') === 'true',
      template: props.profile?.learnPanel?.template || Cookies.get('template') === 'true',
      automation: props.profile?.learnPanel?.automation || Cookies.get('automation') === 'true',
      email:
        props.profile?.mailbox?.user?.length > 0
          ? true
          : props.profile?.learnPanel?.email || Cookies.get('email') === 'true',
    };
  
    dispatch(LEARN_ACTION(payload));
  }, [dispatch, props.profile, isLargeScreen]);
  // useEffect(() => {
  //   const isOpenFromProfile = props?.profile?.learnPanel?.isOpen;
  //   const isOpenFromCookie = Cookies.get('isOpen');
  //   const isOpenValue =
  //     isLargeScreen &&
  //     (isOpenFromProfile !== undefined
  //       ? isOpenFromProfile
  //       : isOpenFromCookie !== undefined
  //       ? isOpenFromCookie === 'true'
  //       : false);
  //   const payload = {
  //     _id: props?.profile?._id,
  //     learnPanel: props?.profile?.learnPanel,
  //     isOpen: isOpenValue,
  //     lead: props.profile?.learnPanel?.lead || Cookies.get('lead') === 'true',
  //     leadsearch: props.profile?.learnPanel?.leadsearch || Cookies.get('leadsearch') === 'true',
  //     leads: props.profile?.learnPanel?.leads || Cookies.get('leads') === 'true',
  //     addlead:
  //       props.profile?.leads?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.addlead || Cookies.get('addlead') === 'true',
  //     enrichlead:
  //       props.profile?.enriched?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.enrichlead || Cookies.get('enrichlead') === 'true',
  //     template: props.profile?.learnPanel?.template || Cookies.get('template') === 'true',
  //     automation: props.profile?.learnPanel?.automation || Cookies.get('automation') === 'true',
  //     email:
  //       props.profile?.mailbox?.user?.length > 0
  //         ? true
  //         : props.profile?.learnPanel?.email || Cookies.get('email') === 'true',
  //   };

  //   dispatch(LEARN_ACTION(payload));
  // }, [dispatch, props.profile, isLargeScreen]);

  // useEffect(() => {
  //   console.log('CHECK PATH', props.location.pathname);
  //   const regex = /\/[a-z]{2}\//i;
  //   const leadSearchRegex = /^\/leadsearch(\/|$)/;
  //   const leadsSearchRegex = /^\/leads(\/|$)/;
  //   if (regex.test(props.location.pathname)) {
  //     console.log('LEARN PANEL', props.location.pathname);
  //     dispatch(UPDATE_LEARN_ACTION('lead', true, props.profile));
  //   }
  //   if (leadSearchRegex.test(props.location.pathname)) {
  //     dispatch(UPDATE_LEARN_ACTION('leadsearch', true, props.profile));
  //   }
  //   if (leadsSearchRegex.test(props.location.pathname)) {
  //     dispatch(UPDATE_LEARN_ACTION('leads', true, props.profile));
  //   }
  // }, [props.location.pathname]);
  useEffect(() => {
    // console.log('CHECK PATH', props.location.pathname);
    const regex = /\/[a-z]{2}\//i;
    const leadSearchRegex = /^\/leadsearch(\/|$)/;
    const leadsSearchRegex = /^\/leads(\/|$)/;
    if (regex.test(props.location.pathname) && !props.profile?.learnPanel?.lead) {
      // console.log('LEARN PANEL', props.location.pathname);
      dispatch(UPDATE_LEARN_ACTION('lead', true, props.profile, learnState));
    }
    if (leadSearchRegex.test(props.location.pathname) && !props.profile?.learnPanel?.leadsearch) {
      dispatch(UPDATE_LEARN_ACTION('leadsearch', true, props.profile, learnState));
    }
    if (leadsSearchRegex.test(props.location.pathname) && !props.profile?.learnPanel?.leads) {
      dispatch(UPDATE_LEARN_ACTION('leads', true, props.profile, learnState));
    }

    //   // If the page is 'lead' or 'leadsearch', set the expanded item to the first uncompleted item
    //   if (leadSearchRegex.test(props.location.pathname) || regex.test(props.location.pathname)) {
    //     const firstUncompletedIndex = options.findIndex((option) => !option.complete);

    //     // Only update expandedIndex if it's null or if it's not pointing to an uncompleted item
    //     if (expandedIndex === null || options[expandedIndex].complete) {
    //       setExpandedIndex(firstUncompletedIndex !== -1 ? firstUncompletedIndex : expandedIndex);
    //     }
    //   }
    // }, [props.location.pathname, options, expandedIndex]);
    // If the page is 'lead' or 'leadsearch', set the expanded item to the first uncompleted item
    if (leadSearchRegex.test(props.location.pathname) || regex.test(props.location.pathname)) {
      const firstUncompletedIndex = options.findIndex((option) => !option.complete);

      // Only update expandedIndex if it's null or if it's not pointing to an uncompleted item
      if (expandedIndex === null || options[expandedIndex].complete) {
        const newExpandedIndex =
          firstUncompletedIndex !== -1 ? firstUncompletedIndex : expandedIndex;

        // Only dispatch UPDATE_LEARN_ACTION if expandedIndex has actually changed
        if (newExpandedIndex !== expandedIndex) {
          setExpandedIndex(newExpandedIndex);
          dispatch(
            UPDATE_LEARN_ACTION('expandedIndex', newExpandedIndex, props.profile, learnState)
          );
        }
      }
    }
  }, [props.location.pathname]);

  // const lead = learn?.lead;

  const isOpen = learn?.isOpen;

  // const isOpen = isLargeScreen && learn?.isOpen;
  // const leadsearch = learn?.leadsearch;

  // console.log('LEARN PANEL OPTIONS', learn, lead, leadsearch, isOpen);
  // const [isOpen, setIsOpen] = useState(
  //   !Cookies.get('learnpanel') || Cookies.get('learnpanel') === 'true'
  // ); // read the cookie

  // useEffect(() => {
  //   setOptions(initialOptions);
  // }, [initialOptions, props.profile]);

  useEffect(() => {
    setOptions((prevOptions) =>
      prevOptions.map((option, index) => {
        const {cookie} = option;
        // console.log('OPTION', option, cookie, learnState[cookie]);
        // if (option.title === 'Visit a Lead') {
        return {
          ...option,
          complete: learnState[cookie],
        };
        // }
      })
    );
  }, [learnState, props.profile]);

  useEffect(() => {
    // console.log('REORDER OPTIONS', options);
    const reorderedOptions = [...options].sort(
      (a, b) => (b.complete ? 1 : 0) - (a.complete ? 1 : 0)
    );
    // console.log('REORDERed OPTIONS', reorderedOptions);
    setOptions(reorderedOptions);

    const firstIncompleteIndex = reorderedOptions.findIndex((option) => !option.complete);
    setExpandedIndex(firstIncompleteIndex !== -1 ? firstIncompleteIndex : null);
  }, [props.profile, learnState]);

  const togglePanel = () => {
    const newValue = !isOpen;
    dispatch(UPDATE_LEARN_ACTION('isOpen', newValue, props.profile, learnState));
  };

  // useEffect(() => {
  //   dispatch(UPDATE_LEARN_ACTION('isOpen', isOpen, props.profile));
  // }, [isOpen, dispatch]);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  if (props.location.pathname === '/start-free') {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '75%',
        right: 0,
        zIndex: '99999',
        transform: 'translateY(-50%)',
      }}>
      <Button
        variant="contained"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '-12px',
          width: '48px', // Adjust size as needed
          height: '48px', // Adjust size as needed
          borderRadius: '6px 0px 0px 6px', // Adjust radius as needed
          padding: 0,
        }}
        startIcon={<SchoolIcon style={{fontSize: '24px', marginBottom: '-4px'}} />}
        onClick={togglePanel}
        onTouchStart={togglePanel}>
        <span style={{fontSize: '12px', marginLeft: '-9px', textTransform: 'capitalize'}}>
          Learn
        </span>
      </Button>
      {isOpen && (
        <Grid
          style={{
            height: '436px',
            padding: '14px',
            width: '245px',
            position: 'fixed',
            marginBottom: '-2px',
            borderRadius: '8px 0px 0px 8px', // Adjust radius as needed
            bottom: 0,
            right: 0,
            boxShadow:
              '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
            backgroundColor: '#fafafa',
          }}>
          <Grid container>
            <Grid item xs={11}>
              <Typography
                variant="h6"
                component="h3"
                color="primary"
                style={{marginTop: '-4px', fontSize: '19px'}}>
                Start finding sales
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ClearIcon
                onClick={() =>
                  dispatch(UPDATE_LEARN_ACTION('isOpen', false, props.profile, learnState))
                }
                style={{fontSize: '18px', marginTop: '-3px', cursor: 'pointer'}}
              />
            </Grid>
          </Grid>
          <Grid style={{marginTop: '4px', padding: '12px', backgroundColor: 'white', height: '94%'}}>
            {options.map((option, index) => (
              <Grid
                key={`${index}-${option.complete}`}
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'column', // Change to column layout
                  position: 'relative',
                }}
                // onTouchStart={() => toggleExpand(index)}
                onClick={() => toggleExpand(index)} // Toggle expansion when clicking anywhere in the grid
              >
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  {learnState[option.cookie] ? (
                    <CheckCircleIcon style={{color: 'green', fontSize: '14px'}} />
                  ) : (
                    option.icon
                  )}
                  <span
                    style={{
                      marginLeft: '10px',
                      fontWeight: '600',
                      cursor: 'pointer',
                      textDecoration: learnState[option.cookie] ? 'line-through' : 'none',
                    }}>
                    {option.title}
                  </span>
                  <ExpandMoreIcon
                    style={{
                      fontSize: '16px',
                      marginLeft: 'auto',
                      cursor: 'pointer',
                      transform: expandedIndex === index ? 'none' : 'rotate(270deg)',
                    }}
                  />
                </Grid>
                {expandedIndex === index && (
                  <Box
                    style={{
                      backgroundColor: '#fff',
                      padding: '10px',
                      zIndex: '999',
                      textAlign: 'left',
                    }}>
                    <Typography variant="body2" style={{fontSize: '12px'}}>
                      {option.explainer}
                    </Typography>
                    {option.buttontext && (
                      <Box style={{marginTop: '10px', textAlign: 'center'}}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{
                            backgroundColor: '#003671',
                            color: 'white',
                            borderRadius: '32px',
                            textTransform: 'capitalize',
                          }}
                          href={option.buttonlink}>
                          {option.buttontext}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
