import {LEARN, ACTIVITY, UPDATE_OPTION_STATE, PROFILE} from 'app/constants/actionTypes';
import {HTTP_REQUEST_ACTIVITY} from 'app/services/httpClient/data.service';
import {setActionType} from 'support/helpers/front';
import Cookies from 'js-cookie';
import {HTTP_UPDATE_PROFILE} from 'app/services/httpClient/profile.service';

/**
 * Learn action.
 *
 * @export
 * @async
 * @function
 * @name LEARN_ACTION
 * @param payload
 * @returns {Function}
 */
export function LEARN_ACTION(payload) {
  // console.log('LEARN ACTION PAYLOAD', payload);
  return async (dispatch, getState) => {
    if (!payload) {
      payload = {
        isOpen: true,
        lead: false,
        leadsearch: false,
        leads: false,
        addlead: false,
        enrichlead: false,
        template: false,
        automation: false,
        email: false,
      };
    }

    // console.log('LEARN ACTION PAYLOAD', payload);
    if (payload && payload._id && !payload._id.includes('-')) {
      const user = await HTTP_UPDATE_PROFILE({
        _id: payload._id,
        'learnPanel.isOpen': payload.isOpen,
        'learnPanel.lead': payload.lead,
        'learnPanel.leadsearch': payload.leadsearch,
        'learnPanel.leads': payload.leads,
        'learnPanel.addlead': payload.addlead,
        'learnPanel.enrichlead': payload.enrichlead,
        'learnPanel.template': payload.template,
        'learnPanel.automation': payload.automation,
        'learnPanel.email': payload.email,
      })
        .then((response) => response.json())
        .then((response) => {
          // const reslength = response.length;
          // if (reslength !== 0) {
          //   toast.success('Saved opt-out text.');
          // }
          payload = response?.data?.learnPanel;
          // console.log('USER RESPONSE', response?.data);
          return response?.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // setSignature(start);
          // toast.error(`Something went wrong. Your send from email is still ${start}.`);
        });

      // console.log('USER', user);
    }

    // if (payload !== undefined && payload !== null) {
    //   Object.entries(payload).forEach(([key, value]) => {
    //     Cookies.set(key, value);
    //   });
    // } else {
    //   // console.error('Object is undefined or null');
    // }

    if (payload !== undefined && payload !== null) {
      Object.entries(payload).forEach(([key, value]) => {
        Cookies.set(key, value, { expires: 365 }); // Cookie expires in 365 days
      });
    }

    dispatch({
      type: setActionType(200, LEARN),
      payload,
    });
  };
}

// /**
//  * Update learn action.
//  *
//  * @export
//  * @function
//  * @name UPDATE_LEARN_ACTION
//  * @param {string} option
//  * @param {boolean} value
//  * @returns {object}
//  */
// export function UPDATE_LEARN_ACTION(option, value, profile) {
//   console.log('PAYLOAD UPDATE_LEARN_ACTION', option, value, profile);
//   return (dispatch, getState) => {
//     if (profile.email) {
//       console.log('HAS EMAIL', option, value, profile);
//       const {_id, learnPanel} = profile; // replace with actual path to _id and learnPanel in your state
//       const updatedLearnPanel = {...learnPanel, [option]: value};
//       if (_id) {
//         HTTP_UPDATE_PROFILE({
//           _id,
//           learnPanel: updatedLearnPanel,
//         })
//           .then((response) => response.json())
//           .then((response) => {
//             // dispatch({
//             //   type: 'UPDATE_LEARN_ACTION',
//             //   payload: response,
//             // });
//             console.log('Response UPDATE_LEARN_ACTION', response, response.data);
//             if (response?.data?._id) {
//               console.log('Response UPDATE_LEARN_ACTION 1', response, response.data);
//               dispatch({
//                 type: setActionType(200, PROFILE),
//                 payload: response ? response.data : undefined,
//               });
//               console.log('Response UPDATE_LEARN_ACTION 2', response, response.data);
//             }
//           })
//           .catch((error) => {
//             // handle error
//             console.log(error);
//           });
//       }
//     }
//     Cookies.set(option, value);
//     return {
//       type: setActionType(200, UPDATE_OPTION_STATE),
//       payload: {option, value},
//     };
//   };
// }
// export function UPDATE_LEARN_ACTION(option, value, profile) {
//   Cookies.set(option, value);
//   return {
//     type: setActionType(200, UPDATE_OPTION_STATE),
//     payload: {option, value},
//   };
// }

// /**
//  * Learn action.
//  *
//  * @export
//  * @async
//  * @function
//  * @name LEARN_ACTION
//  * @param payload
//  * @returns {Function}
//  */
// export function LEARN_ACTION(payload) {
//   return async (dispatch, getState) => {
//     // If there is no initial payload, set the default state and cookie
//     if (!payload) {
//       payload = {
//         isOpen: true,
//         lead: false,
//         leadsearch: false,
//         leads: false,
//         addlead: false,
//         enrichlead: false,
//         template: false,
//         automation: false,
//         email: false,
//       };
//     }

//     // Save each field to a separate cookie
//     Object.entries(payload).forEach(([key, value]) => {
//       Cookies.set(key, value);
//     });

//     // Dispatch Action.
//     dispatch({
//       type: setActionType(200, LEARN),
//       payload,
//     });
//   };
// }

// /**
//  * Learn action.
//  *
//  * @export
//  * @async
//  * @function
//  * @name UPDATE_LEARN_ACTION
//  * @param payload
//  * @returns {Function}
//  */
// export function UPDATE_LEARN_ACTION(option, value) {
//   Cookies.set(option, value);
//   return {
//     type: setActionType(200, UPDATE_OPTION_STATE),
//     payload: {option, value},
//   };
// }

/**
 * Learn action.
 *
 * @export
 * @async
 * @function
 * @name UPDATE_LEARN_ACTION
 * @param payload
 * @returns {Function}
 */
export function UPDATE_LEARN_ACTION(option, value, profile, learnPanel) {
  return async (dispatch) => {
    // If profile and email exist, attempt to update the profile in the database
    if (profile && profile.email) {
      const {_id} = profile;
      const updatedLearnPanel = {...learnPanel, [option]: value};

      if (_id) {
        try {
          const response = await HTTP_UPDATE_PROFILE({
            _id,
            learnPanel: updatedLearnPanel,
          });

          const data = await response.json();

          if (data && data._id) {
            dispatch({
              type: setActionType(200, PROFILE),
              payload: data,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    // Update the cookie and state regardless of whether the profile was updated
    Cookies.set(option, value, { expires: 365 });
    dispatch({
      type: setActionType(200, UPDATE_OPTION_STATE),
      payload: {option, value},
    });
  };
}

/**
 * @async
 * @function
 * @name RESET_LEARN_ACTION
 * @param profile
 * @returns {Function}
 */
export function RESET_LEARN_ACTION(profile) {
  return async (dispatch) => {
    // If profile and email exist, attempt to update the profile in the database
    if (profile && profile.email) {
      const {_id} = profile;
      const resetLearnPanel = {
        isOpen: false,
        lead: false,
        leadsearch: false,
        leads: false,
        addlead: false,
        enrichlead: false,
        template: false,
        automation: false,
        email: false,
      };

      Object.keys(resetLearnPanel).forEach((key) => {
        Cookies.remove(key);
      });

      // if (_id) {
      //   try {
      //     const response = await HTTP_UPDATE_PROFILE({
      //       _id,
      //       learnPanel: resetLearnPanel,
      //     });

      //     const data = await response.json();

      //     if (data && data._id) {
      //       dispatch({
      //         type: setActionType(200, PROFILE),
      //         payload: data,
      //       });
      //     }
      //   } catch (error) {
      //     console.error('Error resetting learn panel: ', error);
      //   }
      // }
    }
  };
}


/**
 * Activity action.
 *
 * @export
 * @async
 * @function
 * @name ACTIVITY_ACTION
 * @param payload
 * @returns {Function}
 */
export function ACTIVITY_ACTION(payload) {
  return async (dispatch, getState) => {
    const ACTIVITY_RESPONSE = await HTTP_REQUEST_ACTIVITY(payload);
    // Dispatch Action.
    dispatch({
      type: setActionType(ACTIVITY_RESPONSE.status, ACTIVITY),
      payload: await ACTIVITY_RESPONSE.json(),
    });
  };
}
